import React, { useEffect, useState } from "react";
import AOS from "aos";
import { Link } from "react-router-dom";

const SectionThree = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const data = [
    {
      title: "Expert Services",
      content: (
        <>
          <p>
            Crafting cutting-edge web design and development solutions, responsiveness, and user-centric experiences.
          </p>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">
              Interface Design
              </p>
              <p className="university">Cambridge University / 2010 - 2014</p>
              <p className="cursus">
              Our Interface Design service focuses on creating visually stunning, 
              user-friendly designs that enhance the overall user experience. 
              We combine creativity with functionality to ensure each interface is 
              intuitive and engaging. Whether for mobile apps or web applications, 
              our designs are crafted to captivate and convert.
              </p>
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">
                UI/UX and Responsiveness
              </p>
              <p className="cursus">
              Our UI/UX and Responsiveness service ensures that every interaction with your 
              digital product is smooth, intuitive, and visually appealing across all devices. 
              We prioritize user experience, crafting interfaces that are both engaging and fully 
              responsive. Our designs adapt seamlessly to any screen size, delivering consistent 
              performance and satisfaction.
              </p>
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">
                UI Kits
              </p>
              <p className="university">Cambridge University / 2016 - 2018</p>
              <p className="cursus">
              At SoloZenith, our UI Kits service provides you with comprehensive, 
              ready-to-use design components that streamline your development process. 
              These kits are meticulously crafted for consistency, ensuring a cohesive and 
              visually appealing user experience across your application. With our UI Kits, 
              you can accelerate your project timelines while maintaining design excellence.
              </p>
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">
                User Centric Designs
              </p>
              <p className="cursus">
              At SoloZenith, our User-Centric Design service is dedicated to creating 
              solutions that prioritize the needs and preferences of your users.
              We focus on understanding user behavior to design intuitive, engaging, 
              and accessible experiences. By placing the user at the center of our design process, 
              we ensure your product resonates deeply and delivers exceptional value.
              </p>
            </div>
          </div>
        </>
      ),
    },
    
  ];

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      {/* <!-- ====================================== About Section Three ===================================== --> */}
      <section className="section-three overflow-hidden" id="explore-me">
        <div className="container">
          <div className="row services-section">
            <div className="col-xxl-4 col-xl-4 col-lg-4">
              <p className="our-services" data-aos="fade-up">
                OUR SERVICES
              </p>
              <h2 className="our-solution" data-aos="fade-up">
                Solution We Provide.
              </h2>
              <p className="uran odio" data-aos="fade-up">
              Elevate your small business with our comprehensive IT services, 
              covering web and mobile applications, AI automations, and more.
              </p>
              <div className="services-circle-main">
                <div
                  className="view-work-circle btn btn1"
                  id="circle1"
                  data-aos="zoom-in-right"
                >
                  <Link to="/portfolio" className="a1">
                    VIEW MY WORK
                  </Link>
                </div>
                <div className="contact-circle" data-aos="zoom-in-left">
                  <div className="view-work-circle btn btn2">
                    <Link to="/contact">CONTACT ME</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-7 col-lg-7">
              <div id="faq-sec">
                {data.map((item, index) => (
                  <div
                    className="nested-accordion"
                    key={index}
                    data-aos="fade-up"
                  >
                    <h3
                      className={`boder-top ${
                        activeIndex === index ? "selected" : ""
                      }`}
                      onClick={() => handleClick(index)}
                    >
                      {item.title}
                    </h3>
                    <div
                      className="comment"
                      style={{
                        display: activeIndex === index ? "block" : "none",
                      }}
                    >
                      {item.content}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== About Section Three End ===================================== --> */}
    </>
  );
};
export default SectionThree;
