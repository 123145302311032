import React, { useEffect } from "react";
import AOS from "aos";
import TrophyImg from "../../assets/images/trophy.png";

const SectionTwo = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* <!-- ====================================== About Section Two ===================================== --> */}
      <section className="about-section-two overflow-hidden">
        <div className="container">
          <div
            className="award-circle-main aos-init aos-animate"
            data-aos="zoom-in"
          >
            <div className="award-circle">
              <img src={TrophyImg} alt="trophy" />
            </div>
            <div>
              <h2 className="independent">Proud Technology Partner</h2>
              <p className="annual">xHrHub | AFS Agency</p>
            </div>
          </div>
          <h3 className="self-taught aos-init aos-animate" data-aos="fade-up">
            <span>A seasoned</span> - technology professional with over 20 years of experience,{" "}
            I lead a <span>startup</span> engineering company focused on transforming <span>innovative</span> ideas into thriving businesses.
          </h3>
          <p className="odio aos-init aos-animate" data-aos="fade-up">
          With over <span>20 years</span> of experience in the technology industry, 
          I have had the privilege of working as a software engineer at 
          some of the most renowned tech giants. My career has been a 
          journey through the ever-evolving landscape of technology, where
           I have led teams at the cutting edge of innovation, tackling some 
           of the most technically challenging projects. <br /><br />

          Now, I am channeling my passion and expertise into a new venture—a 
          startup engineering company dedicated to turning visionary ideas into 
          thriving businesses. My mission is to adopt and incubate innovative concepts, 
          nurturing small businesses into powerful brands and expanding their market reach.
          <br /><br />
          If you have a viable idea, I invite you to reach out to me. Together, 
          we can collaborate to transform your vision into a successful business reality.
          <br /><br />
          Beyond my professional pursuits, I am deeply passionate about technology. 
          In my free time, I immerse myself in music, playing the guitar and participating 
          in musical gigs and jam sessions. I also play soccer in master leagues and take great
          pride in coaching young athletes, inspiring them to pursue their dreams and excel both on and off the field.
          </p>
        </div>
      </section>
      {/* <!-- ====================================== About Section Two End ===================================== --> */}
    </>
  );
};
export default SectionTwo;
